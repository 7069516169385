import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import packageInfo from './../package.json';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {PROJECT_VARIABLES} from './environments/project_variables';

if (environment.production) {
    enableProdMode();
}
Sentry.init({
    dsn: environment.SENTRY_DNS,
    environment: environment.ENV,
    release: packageInfo.version,
    enabled: environment.ENV !== PROJECT_VARIABLES.environments.LOCAL,
    integrations: [],
    sampleRate:1
});
platformBrowserDynamic().bootstrapModule(AppModule).catch((err) => console.error(err));
