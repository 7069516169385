import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {AppConstants} from '../app.constants';
import {catchError} from 'rxjs/operators';

@Injectable()
export class ServiceUnavailableInterceptor implements HttpInterceptor {
    /**
     * Intercept any http request.
     * Listen for errors on any http request and see if it's an 503 else continue as usual.
     * If status error 503 (Service Unavailable) send new HttpResponseError that will be handled
     * by the error handler to show a 'Service Unavailable' warning
     *
     * @param {HttpRequest<any>} request
     * @param {HttpHandler} next
     * @returns {Observable<HttpEvent<any>>}
     */
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // TODO: Remove this and fix this per request in the services instead
        // This prevents us in sending the access token to Amazon (e.g. uploading an image)
        if (request.url.includes(AppConstants.AMAZON_AWS_REQUEST)) {
            return next.handle(request);
        }

        // Listen for errors on the requests
        return next.handle(request).pipe(catchError((errorResponse) => {
            return throwError(() => errorResponse);
        }));
  }
}
